// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #F1F1F1;
}

.contact-page {
  background-color: #313131;
  height: 100vh;
  width: 100vw; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-page .close-button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40px;
  right: 40px;
}

@media (max-width: 768px) {
  .contact-page {
    height: 100vh;
  }
  .contact-page .close-button{
    width: 30px;
    height: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["body {\n  background: #F1F1F1;\n}\n\n.contact-page {\n  background-color: #313131;\n  height: 100vh;\n  width: 100vw; \n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.contact-page .close-button {\n  cursor: pointer;\n  width: 40px;\n  height: 40px;\n  position: absolute;\n  top: 40px;\n  right: 40px;\n}\n\n@media (max-width: 768px) {\n  .contact-page {\n    height: 100vh;\n  }\n  .contact-page .close-button{\n    width: 30px;\n    height: 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
